import { Button, Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ListSections = ({ items = [], isSection }) => {
  const navigate = useNavigate();
  const { currentLanguage: LANGUAGE, translations } = useSelector(
    (state) => state.localizationData
  );

  const itemClickHandler = (id = "") => {
    navigate(`sections/${id}`);
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      {items.map((item) => {
        return (
          <Grid item xs={12} sm={4} key={item.id}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => itemClickHandler(item.id)}
              fullWidth
              sx={{
                py: 2,
                textTransform: "none",
                borderRadius: 2,
              }}
            >
              {item[LANGUAGE]}
            </Button>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ListSections;
