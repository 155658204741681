import {
  ArrowBack,
  ArrowForward,
  ViewCarousel,
  ViewModule,
} from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import VerseCard from "../Card";

const ListVerses = ({ items = [], sectionDetails }) => {
  const { currentLanguage: LANGUAGE, translations } = useSelector(
    (state) => state.localizationData
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [showAll, setShowAll] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const toggleView = () => {
    setShowAll((prev) => !prev);
    setCurrentIndex(0);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const handlePrevious = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + items.length) % items.length
    );
  };

  return (
    <Box position="relative" minHeight="100vh">
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <IconButton color="primary" onClick={toggleView}>
          {showAll ? (
            <ViewCarousel fontSize="large" />
          ) : (
            <ViewModule fontSize="large" />
          )}
        </IconButton>

        {!showAll && (
          <Box
            position="fixed"
            top={30} // Adjust top spacing for mobile
            left="50%"
            zIndex={10}
            sx={{
              transform: "translateX(-50%)",
              display: "flex",
              alignItems: "center",
              gap: isMobile ? 5 : 15, // Reduce gap on smaller screens
              width: "90%", // Adjust width for responsiveness
              justifyContent: "space-between",
            }}
          >
            <IconButton
              color="primary"
              onClick={handlePrevious}
              sx={{
                bgcolor: "white",
                borderRadius: "50%",
                boxShadow: 3,
                border: "1px solid #ccc",
                "&:hover": { bgcolor: "#f0f0f0" },
                width: isMobile ? 36 : 48, // Smaller size for mobile
                height: isMobile ? 36 : 48,
              }}
            >
              <ArrowBack fontSize={isMobile ? "medium" : "large"} />
            </IconButton>
            <Typography
              sx={{
                fontSize: { xs: "1rem", sm: "1.5rem", md: "2rem" }, // Dynamic font size
                fontWeight: "bold",
                textAlign: "center",
                flexShrink: 1, // Prevents overflow
                maxWidth: "60%", // Limit width on small screens
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis", // Prevents text overflow
                bgcolor: "primary.main", // Background color from theme
                color: "white", // Text color
                px: 1, // Horizontal padding
                borderRadius: 2, // Rounded corners
                boxShadow: 3, // Subtle shadow for depth
                mt: 1,
              }}
            >
              {sectionDetails?.[LANGUAGE]}
            </Typography>

            <IconButton
              color="primary"
              onClick={handleNext}
              sx={{
                bgcolor: "white",
                borderRadius: "50%",
                boxShadow: 3,
                border: "1px solid #ccc",
                "&:hover": { bgcolor: "#f0f0f0" },
                width: isMobile ? 36 : 48,
                height: isMobile ? 36 : 48,
              }}
            >
              <ArrowForward fontSize={isMobile ? "medium" : "large"} />
            </IconButton>
          </Box>
        )}
      </Box>

      <Grid container spacing={2} justifyContent="center">
        {items?.length === 0 ? (
          <>Please check later, currently no verses have been added</>
        ) : showAll ? (
          items.map((item) => (
            <VerseCard key={item.id} verse={item} language={LANGUAGE} />
          ))
        ) : (
          <Grid item xs={12}>
            <VerseCard
              verse={items[currentIndex]}
              language={LANGUAGE}
              isSingleVerse
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ListVerses;
