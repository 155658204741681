import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { SnackbarProvider } from "./components/Snackbar";
import Verses from "./pages/Verses";
import { theme } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import AdminVersesForm from "./pages/Admin";
import AdminSectionsForm from "./pages/Admin/sections";
import Dashboard from "./pages/Dashboard";
import React, { useEffect } from "react";
import { NavBar } from "./components/Navbar";
import { MobileDrawer } from "./pages/Home/MobileDrawer";
import Home from "./pages/Home";
import Bible from "./pages/Bible";
import { Movies } from "./pages/Movies";
import { Books } from "./pages/Books";
import { Prayer } from "./pages/Prayer";
import { AboutUs } from "./pages/AboutUs";
import { AdminSections2 } from "./pages/Admin/addSections2";

function App() {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const scheduleDailyNotification = () => {
    const now = new Date();
    const targetTime = new Date();

    // Set target time to 2:00 PM
    targetTime.setHours(13, 33, 0, 0);

    // If it's already past 2:00 PM today, schedule for tomorrow
    if (targetTime <= now) {
      targetTime.setDate(targetTime.getDate() + 1);
    }

    const initialDelay = targetTime - now; // Time in milliseconds

    console.log(`First notification in ${initialDelay / 1000} seconds`);

    // Trigger the first notification after the initial delay
    setTimeout(() => {
      showNotification();

      // After the first notification, set an interval for every 24 hours
      setInterval(() => {
        showNotification();
      }, 24 * 60 * 60 * 1000); // 24 hours in milliseconds
    }, initialDelay);
  };

  const showNotification = () => {
    if (Notification.permission === "granted") {
      new Notification("Reminder!", {
        body: "This is your daily notification at 2 PM.",
      });
    } else {
      console.error("Notification permission not granted.");
    }
  };

  useEffect(() => {
    if ("Notification" in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          scheduleDailyNotification();
        } else {
          console.error("Notification permission denied.");
        }
      });
    } else {
      console.error("This browser does not support notifications.");
    }
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider>
        <BrowserRouter>
          {/* <NavBar onDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} />
          <MobileDrawer
            onDrawerToggle={handleDrawerToggle}
            mobileOpen={mobileOpen}
          /> */}
          <main>
            <Routes>
              {/* <Route path="/" element={<Home />} /> */}
              <Route path="/home" element={<Home />} />
              <Route path="/" element={<Bible />} />
              <Route path="/bible" element={<Bible />} />
              <Route path="/sections/:id" element={<Verses />} />
              <Route path="/bible/sections/:id" element={<Verses />} />
              <Route path="/admin" element={<AdminVersesForm type="admin" />} />
              <Route path="/admin/section" element={<AdminSectionsForm />} />

              <Route
                path="/wallp"
                element={<AdminVersesForm type="wallpaper" />}
              />
              <Route path="/admin/dashboard" element={<Dashboard />} />
              <Route
                path="/admin/verse/:id"
                element={<AdminVersesForm type="admin" />}
              />
              <Route path="/movies" element={<Movies />} />
              <Route path="/books" element={<Books />} />
              <Route path="/prayer" element={<Prayer />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/admin2" element={<AdminSections2 />} />
            </Routes>
          </main>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
