// src/Counter.js

import React, { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useGetVersesMutation } from "../../store/verses/service";
import { setLocalStorage } from "../../utils/localStorage";
import { setCurrentLanguage } from "../../store/localization/slice";
import { useDispatch, useSelector } from "react-redux";
import ListVersesWrapper from "../../section/ListVersesWrapper";

const Verses = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const language = searchParams.get("lang");
  const dispatch = useDispatch();
  const [getVerses, { isLoading }] = useGetVersesMutation();
  const sections = useSelector((state) => state.versesData.sections);
  const sectionDetails = sections.find((section) => section.id === id);

  useEffect(() => {
    getVersesHandler();
  }, []);

  useEffect(() => {
    if (language) {
      setLocalStorage("language", language);
      dispatch(setCurrentLanguage(language));
    }
  }, [language]);

  const getVersesHandler = async () => {
    try {
      await getVerses(id);
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
  };

  return (
    <ListVersesWrapper loading={isLoading} sectionDetails={sectionDetails} />
  );
};

export default Verses;
