import { Box, Card, CardContent, Container, Skeleton } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import ListVerses from "../ListVerses";

const ListVersesWrapper = ({ image, loading, sectionDetails }) => {
  const { verses = [] } = RootState()?.versesData;

  return (
    <Container maxWidth="md" sx={{ py: 2 }}>
      <Card variant="outlined" sx={{ p: 2, borderRadius: 2, boxShadow: 3 }}>
        <CardContent>
          {image ? (
            <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
              <img
                src={image}
                alt="App Logo"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  maxHeight: "150px",
                }}
              />
            </Box>
          ) : null}

          {loading ? (
            <Skeleton
              variant="text"
              width="30%"
              height={70}
              sx={{ mb: 2, mx: "auto" }}
            />
          ) : (
            <ListVerses items={verses} sectionDetails={sectionDetails} />
          )}
        </CardContent>
      </Card>
    </Container>
  );
};

export default ListVersesWrapper;
